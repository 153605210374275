* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Satoshi-Variable';
  src: url('./fonts/Satoshi-Variable.woff2') format('woff2');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

.App {
  width: 100vw;
  text-align: center;
  padding-bottom: 5%;
}

.appBody {
  width: 100vw;
  
}

.App-header p{
  font-family: 'Satoshi-Variable';
  font-weight: 900;
  font-size: 5em;
  text-align: left;
  margin-left: 0.5em;
  margin-top: 0.3em;
  color: #a3b18a;
}

