.waitingSeqs {
    font-family: 'Satoshi-Variable';
    font-size: 1.5em;
    color: #3a5939;
}

.tooltip {
    position: absolute;
    opacity: 0;
    width: 100px;
    background-color: white;
    border: solid;
    border-color: #3a5939;
    border-width: 1px;
    border-radius: 5px;
    padding: 10px;
}