@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;600;800&display=swap');

@font-face {
    font-family: 'Satoshi-Variable';
    src: url('../fonts/Satoshi-Variable.woff2') format('woff2');
    font-weight: 300 900;
    font-display: swap;
    font-style: normal;

}

/* .form-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border: 1px solid red;
} */

.filterContainer{
    border: 2px solid #CCD7BA;
    margin: 2.8em;
    border-radius: 16px;
    font-family: 'Satoshi-Variable';
    background-color: #D2DBC3;
    color: #3a5939;
    padding-bottom: 2em;
}

.filterContainer p{
    text-align: left;
    font-size: 1.7em;
    margin-left: 1.4em;
    margin-top: 0.5em;
}

.subjectTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inputsContainer {
    display: none !important;
}

.plus {
    cursor: pointer;
}

.show {
    display: flex !important;
    flex-direction: row;
    gap: 1em;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #EFEBDC;
    border-radius: 20px;
    width: 95%;
    height: 8em;
}

.labelsContainer {
    font-size: 0.7em;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 0.8em;
    user-select: none;
    cursor: pointer;
}

.selected {
    font-weight: 900;
    border-bottom: 2px solid black;
}

.submitBtn {
    border-radius: 16px !important;
    font-family:'Satoshi-Variable' !important;
    text-transform: none !important;
}

.filterBox {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

/* .inputsContainer.cloneSize{
    width: 90%;
}
.inputsContainer.topClones{
    width: 90%;
} */

.selectedFilter {
    display: flex;
    flex-direction: row;
    background-color: #EFEBDC;
    font-size: 0.6em;
    margin: 0.5em;
    padding-right: 1.5em;
    text-align: center;
    border-radius: 20px;
    width: fit-content;
}

.selectedFilter p {
    margin-top: 0.1em;
    margin-left: 1em;
}

.showFiltersContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 2.1em;
}
