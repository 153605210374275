.treeView {
    background-color: white;
    border: 1px;
    border-radius: 16px;
    border-style: solid;
    border-color: #53714B;
    max-width: 40vw;
    text-align: left;
    cursor: move;
    margin: 0 !important;
    font-family: 'Satoshi-Variable' !important;
}

.viewOptions {
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 1px;
    border-style: solid;
    border-color: #53714B !important;
    padding: 2em;
}

.infoTps{
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1em;
}


.viewDetails {
    border-bottom: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    border-style: solid;
    border-color: #53714B !important;
    padding: 1%;
}

h2{
    color: #53714B;
    margin-left: 1em;
}

.viewInfo{
    font-size: 0.8em !important;
    margin-left: 2em;
}

.viewInfoWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #D2DBC3;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    padding: 1em;
}

.metadataSelects {
    display: flex;
    flex-direction: column;
}