.svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    vertical-align: top;
    overflow: hidden;
}
.svg-content {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}

.infoText {
    color: black;
    font-size: 0.8em;
}

.subTitle {
    font-size: 1em;
}

.mutations {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mutations > p {
    padding: 0.2em;
}
