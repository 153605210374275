.views {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 2.5%;
    font-family:'Satoshi-Variable' !important;

}

.viewOrganizer {
    display: flex;
    flex-direction: row;
    padding: 0.5em;
    align-items: center;
    background-color: #D2DBC3;
    width: 80%;
    height: 30%;
    margin-left: 2.5%;
    border-radius: 16px;
    min-height: 18vh;
}

.itemViewOrganizer{
    text-align: center;
    background-color: #A8B396;
    color: #fff;
    font-size: 1.2em;
    margin: 1em;
    border-radius: 20px;
    height: 5em;
    width: 10em;
    border: 1px solid #A8B396;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

/* p{
    margin: 0;
    margin-top: 0.5em;
    margin-bottom: 0.3em;
    font-size: 1.3em;
    color: black;
} */

.buttonView {
    background-color: #53714B !important;
    border-radius: 15px !important;
    font-size: 0.7em !important;
    margin-left: 0.2em !important;
    margin-right: 0.2em !important;
    color: #D2DBC3 !important;
}

.buttonView:hover {
    color: white;
}

.newView{
    color: white !important;
    background-color:#53714B !important;
    text-transform: none !important;
    font-size: 1.2em !important;
    height: 4em;
    padding-left: 4em;
    padding-right: 4em;
    font-weight: 500;
}

.newView:hover{
    font-weight: 700;
    
}

.addViewForm{
    border: 2px solid #CCD7BA;
    margin: 2.8em;
    border-radius: 16px;
    font-family: 'Satoshi-Variable';
    background-color: #D2DBC3;
    color: #3a5939;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.buttonViewAdd {
    border-radius: 20px !important;
    background-color: #3a5939 !important;
    color: white !important;
    text-transform: none !important;
    font-size: 1em !important;
    text-align: center !important;
}
.buttonViewClose {
    border-radius: 20px !important;
    background-color: #3a5939 !important;
    color: white !important;
    text-transform: none !important;
    text-align: center !important;
}

.viewFormContainer {
    background-color: #EFEBDC;
    margin: 1em;
    border-radius: 16px;
}

.viewsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}